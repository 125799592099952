
import { defineComponent, computed, reactive, ref, onMounted } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { noEmptyProp, not } from '@/utils/illuminate';
import { useRouter } from 'vue-router';
import type { TableColumn } from '@/components/Table';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import { request } from '@/utils/request';
import moment from 'moment';
import { useState } from '@/store';
import AddCardModal from './parts/AddCardModal.vue';
import DistributeModal from './parts/DistributeModal.vue';
import { cardList, batchCardStatusList, batchCardStatusListForForm } from './dict';


export default defineComponent({
  name: 'DiscountCardBatch',
  components: {
    AddCardModal,
    DistributeModal,
  },
  setup(props, ctx) {
    const state = useState();
    const router = useRouter();

    const isStateLoading = ref(false);
    const dataPage = ref([]); // 当前页面数据
    const dataState = ref(false); // 请求数据状态

    const filterParams = reactive({
      batchId: '',
      enterpriseId: '',
      cardType: '',
      cardRuleStatus: '',
      applyTime: [],
    });

    const transformTime = (prefix:string, arr: Array<string>) => {
      if (Array.isArray(arr) && arr.length === 2) {
        const start = `${prefix}Begin`;
        const end = `${prefix}End`;
        return { [start]: moment(arr[0]).format('YYYY-MM-DD 00:00:00'), [end]: moment(arr[1]).format('YYYY-MM-DD 23:59:59') };
      }
      return {};
    };

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      const applyTime = transformTime('applyDate', filterParams.applyTime);
      request.post(
        createApiUrl('/newlinkSass/prepaidCard/queryPrepaidCardRuleList'),
        { ...parameter, ...noEmptyProp(not(filterParams, ['applyTime'])), ...applyTime },
        { noEnterpriseId: true },
      )
        .then((res: any) => {
          const { data } = res;
          isStateLoading.value = false;
          dataPage.value = data.list; // 当前Table数据
          dataState.value = true; // 数据第一次加载
          resolve(data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };
    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '批次ID', dataIndex: 'batchId', align: 'center' },
        { title: '所属企业', dataIndex: 'enterpriseName', align: 'center' },
        { title: '卡名称', dataIndex: 'cardName', align: 'center' },
        { title: '卡类型', dataIndex: 'cardType', align: 'center', customRender: ({ text }): string => cardList[text as keyof typeof cardList] },
        { title: '面额', dataIndex: 'nominalAmount', align: 'center' },
        { title: '数量', dataIndex: 'num', align: 'center' },
        { title: '有效期', dataIndex: 'effectiveDays', align: 'center', slots: { customRender: 'effectiveDays' } },
        { title: '购买人', dataIndex: 'buyerName', align: 'center' },
        { title: '购买时间', dataIndex: 'applyDate', align: 'center' },
        { title: '激活时间', dataIndex: 'activationTime', align: 'center' },
        { title: '状态', dataIndex: 'cardRuleStatus', align: 'center', customRender: ({ text }): string => batchCardStatusList[text as keyof typeof batchCardStatusList] },
        { title: '操作', dataIndex: 'action', width: '180px', align: 'center', slots: { customRender: 'action' }, fixed: 'right' },
      ];
      return basic;
    });

    const goBatchDetail = (row: Record<string, any>) => {
      router.push({ name: 'repaidCard.batchDetail', query: { batchId: row.batchId } });
    };

    const addCardRef = ref<any>(null);
    const showModal = ref<boolean>(false);
    const onShowCard = () => {
      showModal.value = true;
    };
    const closeModal = (isRefresh = false) => {
      showModal.value = false;
      if (isRefresh) {
        onRefreshTable();
      }
    };

    // showDistributeModal
    const showDistributeModal = ref<boolean>(false);
    const closeDistributeModal = (isRefresh = false) => {
      showDistributeModal.value = false;
      if (isRefresh) {
        onRefreshTable();
      }
    };

    const batchInfo = ref({});
    const onDistribute = (row: Record<string, any>) => {
      batchInfo.value = row;
      showDistributeModal.value = true;
    };

    return {
      filterParams,
      tableRef,
      tableColumns,
      dataPage,
      dataState,

      loadTableData,
      onRefreshTable,

      isStateLoading,

      addCardRef,
      showModal,
      onShowCard,
      closeModal,
      showDistributeModal,
      closeDistributeModal,
      onDistribute,
      batchInfo,

      batchCardStatusList,
      batchCardStatusListForForm,
      cardList,

      goBatchDetail,
      sldPageTitle,
    };
  },
});
