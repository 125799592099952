// 卡类型
export const cardList = {
  e: '电子卡',
};

// 卡批次状态 -- 列表展示使用
export const batchCardStatusList = {
  wait: '购卡中',
  buying: '购卡中',
  activated: '已激活',
  failed: '购卡失败',
};

// 卡批次状态 -- 查询条件
export const batchCardStatusListForForm = {
  buying: '购卡中',
  activated: '已激活',
  failed: '购卡失败',
};

// 卡状态
export const cardStatusList = {
  effective: '可用',
  invalid: '不可用',
};

// 绑定状态
export const bindStatusList = {
  1: '已绑定',
  0: '未绑定',
};

// 订单状态1 无效  0 待支付 1 已支付 2 退款中  3 部分支付 4 订单取消 5 已退款6 部分退款 7 退款失败
export const orderStatusList = {
  '-1': '无效',
  0: '待支付',
  1: '已支付',
  2: '退款中',
  3: '部分支付',
  4: '订单取消',
  5: '已退款',
  6: '部分退款',
  7: '退款失败',
};

// 下单方式
export const payTypeList = {
  3: '支付宝',
  4: '微信',
  8: '车辆支付',
  9: '账户支付',
  11: '企业支付',
  13: '银联在线',
  14: '预付卡支付',
};
