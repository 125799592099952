
import { defineComponent, reactive, ref, h } from 'vue';
import { Modal, message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { RuleObject } from 'ant-design-vue/es/form/interface';
import { maskMobile } from '@/utils/illuminate';
import { envIs } from '@/utils/env';
import store from '@/store';
import DetailForm from './DetailForm.vue';

export default defineComponent({
  components: {
    DetailForm,
  },
  emits: ['close'],
  setup(props, ctx) {
    const visible = ref<boolean>(true);
    const loading = ref<boolean>(false);
    const checkRules = ref<boolean>(false);
    const phoneValidVisible = ref<boolean>(false);
    const userMobile = ref<string>(maskMobile(store.state.User!.userInfo.mobile!)); // 用户手机号
    const enterpriseName = ref<string>(store.state.User?.enterprise?.enterpriseName as string);
    const webUrl = `${process.env.VUE_APP_WEBVIEW_URL}/pages/junkaRules.html`;

    const showModal = () => {
      visible.value = true;
    };

    const formRef = ref();
    const cardModel = reactive({
      enterpriseId: store.state.User!.userInfo.enterpriseId,
      enterpriseName: '',
      cardName: '',
      cardType: 'e',
      nominalAmount: '',
      num: '',
    });
    const checkEnterpriseId = async (rule: RuleObject, value: number) => {
      if (!value) {
        return Promise.reject('请选择');
      }
      return Promise.resolve();
    };
    const rules = {
      // enterpriseId: [{ required: true, validator: checkEnterpriseId, trigger: 'change' }],
      cardType: [{ required: true, message: '请选择', trigger: 'blur' }],
      nominalAmount: [{ required: true, message: '请输入', trigger: 'blur', type: 'number' }],
      num: [{ required: true, message: '请输入', trigger: 'blur', type: 'number' }],
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };

    const showDetail = ref<boolean>(false);
    const lastStep = () => {
      showDetail.value = false;
    };
    const detailInfo:Record<string, any> = ref({
      settlementPrice: '',
      totalSettlementPrice: '',
      totalDenomination: '',
    });

    let enterpriseObj = reactive({
      enterpriseName: '',
    });
    const changeEnterprise = (val:any) => {
      if (val) {
        enterpriseObj = val;
      } else {
        enterpriseObj = {
          enterpriseName: '',
        };
      }
    };
    const nextStep = () => {
      formRef.value
        .validate()
        .then(async () => {
          if (!checkRules.value) return message.error('请勾选章程协议');
          cardModel.enterpriseName = enterpriseObj.enterpriseName;
          try {
            const { data } = await request.post(createApiUrl('/newlinkSass/prepaidCard/preCalc'), cardModel);
            detailInfo.value = data;
            showDetail.value = true;
          } catch (e:any) {
            message.error(e.message);
          }
        });
    };

    // 倒计时方法
    const validCode = ref<string>(''); // 验证码
    const countDownTime = ref<number>(0); // 验证码倒计时
    const countDown = () => {
      countDownTime.value = 60;
      let timer:number | null = setInterval(() => {
        countDownTime.value--;
        if (countDownTime.value <= 0) {
          clearInterval(timer as number);
          timer = null;
        }
      }, 1000);
    };

    const sendPhoneCode = async () => {
      try {
        const { code } = await request.get(createApiUrl('/newlinkSass/enterprise/captchar/mobile/send'), { params: { mobile: store.state.User!.userInfo.mobile } });
        if (code !== 200) return;
        phoneValidVisible.value = true;
        visible.value = false;
      } catch (error) {
        console.log(error);
      }
    };

    const handleOk = (e: MouseEvent) => {
      if (countDownTime.value > 0) return;
      countDown();
      sendPhoneCode();
    };
    const handleCancel = (type:string) => {
      if (type === 'modal') {
        ctx.emit('close');
        return;
      }
      Modal.confirm({
        title: '提示',
        content: '确认退出预付卡购买?',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          ctx.emit('close');
        },
      });
    };

    // 输入验证码
    const inputCode = async () => {
      try {
        phoneValidVisible.value = false;
        const { code } = await request.post(createApiUrl('/newlinkSass/prepaidCard/applyBuyCard'), { ...cardModel, mobile: store.state.User!.userInfo.mobile, verificationCode: validCode.value });
        if (code !== 200) return;
        Modal.success({
          title: '购买成功',
          content: '您的购买请求已成功提交至发卡公司处理。您购买的电子卡在生产完成后将直接发放到企业后台账户内，您可直接绑定员工或导出卡密。',
          onOk() {
            ctx.emit('close', true);
          },
        });
      } catch (e:any) {
        message.error(e.message);
      } finally {
        ctx.emit('close');
      }
    };

    return {
      webUrl,
      visible,
      loading,
      showModal,
      handleOk,
      handleCancel,
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      cardModel,
      rules,
      resetForm,
      showDetail,
      lastStep,
      nextStep,
      enterpriseObj,
      detailInfo,
      changeEnterprise,
      checkRules,
      userMobile,
      phoneValidVisible,
      inputCode,
      validCode,
      countDownTime,
      enterpriseName,
      isProd: envIs('prod'),
    };
  },
});
