
import { defineComponent, computed, reactive, ref } from 'vue';
import { message, Modal } from 'ant-design-vue';
import type { TableColumn } from '@/components/Table';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import { request } from '@/utils/request';
import { useState } from '@/store';
import moment, { Moment } from 'moment';
import { dispatchDownload, noEmptyProp, not } from '@/utils/illuminate';
import { orderStatusList } from './dict';


export default defineComponent({
  name: 'RepaidCardRecord',
  setup(props, ctx) {
    const state = useState();

    const dataPage = ref([]); // 当前页面数据
    const dataState = ref(false); // 请求数据状态

    const filterParams = reactive({
      cardNo: '',
      batchId: '',
      enterpriseId: '',
      bindMobile: '',
      orderNo: '',
      orderPayTime: [],
    });

    const transformTime = (prefix:string, arr: Array<string>) => {
      if (Array.isArray(arr) && arr.length === 2) {
        const start = `${prefix}Begin`;
        const end = `${prefix}End`;
        return { [start]: moment(arr[0]).format('YYYY-MM-DD 00:00:00'), [end]: moment(arr[1]).format('YYYY-MM-DD 23:59:59') };
      }
      return {};
    };
    const getParams = () => {
      let listParams: Record<string, any> = {};
      const orderPayTime = transformTime('orderPayTime', filterParams.orderPayTime);
      listParams = {
        ...noEmptyProp(not(filterParams, ['orderPayTime'])),
        ...orderPayTime,
      };
      return listParams;
    };

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      request.post(createApiUrl('/newlinkSass/prepaidCard/queryPrepaidCardConsumeList'), 
        { ...parameter, ...getParams() }, 
        { noEnterpriseId: true })
        .then((res: any) => {
          const { data } = res;
          dataPage.value = data.list; // 当前Table数据
          dataState.value = true; // 数据第一次加载
          resolve(data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });
    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };
    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '预付卡卡号', dataIndex: 'cardNo', align: 'center' },
        { title: '批次ID', dataIndex: 'batchId', align: 'center' },
        { title: '所属企业', dataIndex: 'enterpriseName', align: 'center' },
        { title: '消费金额', dataIndex: 'usedAmount', align: 'center' },
        { title: '消费后余额', dataIndex: 'leftAmountAfter', align: 'center' },
        { title: '订单号', dataIndex: 'orderNo', align: 'center' },
        { title: '订单总额', dataIndex: 'totalOrderAmount', align: 'center' },
        { title: '员工手机号', dataIndex: 'bindMobile', align: 'center' },
        { title: '订单状态', dataIndex: 'orderStatus', align: 'center', customRender: ({ text }): string => orderStatusList[text as keyof typeof orderStatusList] },
        { title: '消费时间', dataIndex: 'consumeTime', align: 'center' },
      ];
      return basic;
    });

    // = ---------------------------- = 导出数据 = ---------------------------- =
    const confirmVisible = ref(false);
    const exportParams = reactive<{
      timeRange: Moment[];
    }>({
      timeRange: [],
    });

    const onExport = () => {
      Object.assign(exportParams, {
        timeRange: [],
      });
      confirmVisible.value = true;
    };

    let firstSelectTime: Moment | null;
    const isDateDisable = (currentDate: Moment) => {
      if (moment().valueOf() - currentDate.valueOf() < 0) return true;
    };
    const onOpenChange = (value: boolean) => {
      if (!value) firstSelectTime = null;
    };

    const onCalendarChange = (dateRange: [Moment, Moment]) => {
      // eslint-disable-next-line prefer-destructuring
      firstSelectTime = dateRange[0];
    };

    const exporting = ref(false);
    const onConfirmExport = async () => {
      // const params: Record<string, any> = {};
      // if (!exportParams.timeRange.length) {
      //   message.warning('请选择时间范围在导出');
      //   return;
      // }
      // if (exportParams.timeRange.length) {
      //   params.startDate = exportParams.timeRange[0].format('YYYY-MM-DD');
      //   params.endDate = exportParams.timeRange[1].format('YYYY-MM-DD');
      // }
      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/excel/down/prepaidCard/cardConsume/list'), { ...getParams() }, { responseType: 'blob', noEnterpriseId: true });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhh:mm:ss');
      dispatchDownload(`预付卡消费记录${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      confirmVisible.value = false;
    };

    return {
      filterParams,
      orderStatusList,
      tableRef,
      tableColumns,
      dataPage,
      dataState,
      loadTableData,
      onRefreshTable,
      
      isDateDisable,
      onOpenChange,
      onCalendarChange,
      onExport,
      confirmVisible,
      exporting,
      exportParams,
      onConfirmExport,
      sldPageTitle,
    };
  },
});
