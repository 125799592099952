
import { defineComponent, computed, reactive, ref, onMounted } from 'vue';
import { message, Modal } from 'ant-design-vue';

import { LeftOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import type { TableColumn } from '@/components/Table';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { APP_ENV } from '@/utils/env';

import moment, { Moment } from 'moment';
import { useState } from '@/store';
import { dispatchDownload, maskMobile, noEmptyProp, not } from '@/utils/illuminate';
import ConfirmModal from './parts/ConfirmModal.vue';
import { bindStatusList, cardStatusList } from './dict';


export default defineComponent({
  name: 'DiscountCardBatch',
  components: {
    LeftOutlined,
    ConfirmModal,
  },
  setup(props, ctx) {
    const state = useState();
    const h5Url = ref<string>(APP_ENV.VUE_APP_ENV === 'production' ? 'https://nlclienth5.tyoug.com/bindPrepaidCard' : 'https://test02-nlclienth5.tyoug.com/bindPrepaidCard');
    
    const isStateLoading = ref(false);
    const dataPage = ref([]); // 当前页面数据
    const dataState = ref(false); // 请求数据状态

    const filterParams = reactive({
      cardNo: '',
      bindMobile: '',
      bindTime: [],
      endTermOfValidity: [],
      bindStatus: '',
      cardStatus: '',
    });

    // 加载数据方法 必须为 Promise 对象
    const route = useRoute();
    const transformTime = (prefix:string, arr: Array<string>) => {
      if (Array.isArray(arr) && arr.length === 2) {
        const start = `${prefix}Begin`;
        const end = `${prefix}End`;
        return { [start]: moment(arr[0]).format('YYYY-MM-DD 00:00:00'), [end]: moment(arr[1]).format('YYYY-MM-DD 23:59:59') };
      }
      return {};
    };
    const getParams = () => {
      let listParams: Record<string, any> = {};
      const bindTime = transformTime('bindDate', filterParams.bindTime);
      const endTermOfValidity = transformTime('endTermOfValidity', filterParams.endTermOfValidity);
      listParams = {
        batchId: route.query.batchId,
        ...noEmptyProp(not(filterParams, ['bindTime', 'endTermOfValidity'])),
        ...bindTime,
        ...endTermOfValidity,
      };
      return listParams;
    };
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      request.post(
        createApiUrl('/newlinkSass/prepaidCard/queryPrepaidCardList'),
        { ...parameter, ...getParams() },
        { noEnterpriseId: true },
      )
        .then((res: any) => {
          const { data } = res;
          data.list.map((item: any) => {
            item.stateBoolean = item.state === 'OPEN';
          });
          isStateLoading.value = false;
          dataPage.value = data.list; // 当前Table数据
          dataState.value = true; // 数据第一次加载
          resolve(data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const detailInfo = ref({
      batchId: String,
      enterpriseName: String,
      cardName: String,
      unbindCardNum: Number,
      hasBindCardNum: Number,
      totalNominalAmount: Number,
      totalLeftAmount: Number,
    });
    const loadBatchCardDetailById = () => {
      request.post(createApiUrl('/newlinkSass/prepaidCard/queryPrepaidCardRuleDetail'), { batchId: route.query.batchId }, { noEnterpriseId: true })
        .then(({ data }) => {
          detailInfo.value = data || {};
        }).catch(e => {
          message.error(e.message);
        });
    };
    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '预付卡ID', dataIndex: 'cardNo', align: 'center' },
        { title: '批次ID', dataIndex: 'batchId', align: 'center' },
        { title: '所属企业', dataIndex: 'enterpriseName', align: 'center' },
        { title: '预付卡名称', dataIndex: 'cardName', align: 'center' },
        { title: '面额', dataIndex: 'nominalAmount', align: 'center' },
        { title: '余额', dataIndex: 'leftAmount', align: 'center' },
        { title: '绑定用户', dataIndex: 'bindMobile', align: 'center' },
        { title: '购买时间', dataIndex: 'applyDate', align: 'center' },
        { title: '绑定时间', dataIndex: 'bindDate', align: 'center' },
        { title: '过期时间', dataIndex: 'endTermOfValidity', align: 'center' },
        { title: '状态', dataIndex: 'cardStatus', align: 'center', customRender: ({ text }): string => cardStatusList[text as keyof typeof cardStatusList] },
        { title: '操作', dataIndex: 'action', width: '180px', align: 'center', slots: { customRender: 'action' }, fixed: 'right' },
      ];
      return basic;
    });

    onMounted(() => {
      loadBatchCardDetailById();
    });

    // 分配
    const showConfirmModal = ref<boolean>(false);
    const closeConfirmModal = (isRefresh = false) => {
      showConfirmModal.value = false;
      if (isRefresh) {
        onRefreshTable();
        loadBatchCardDetailById();
      }
    };
    const confirmInfo = ref({});
    const onDistribute = (row: Record<string, any>) => {
      confirmInfo.value = row;
      showConfirmModal.value = true;
    };

    // = ---------------------------- = 导出数据 = ---------------------------- =
    const confirmVisible = ref(false);

    const onExport = ():void => {
      confirmVisible.value = true;
    };

    const userMobile = ref<string>(maskMobile(state.userInfo.value.mobile!)); // 用户手机号
    // 倒计时方法
    const validCode = ref<string>(''); // 验证码
    const countDownTime = ref<number>(0); // 验证码倒计时
    let timer: number | null;

    const clearTimer = () => {
      if (timer) {
        clearInterval(timer as number);
        timer = null;
      }
    };

    const countDown = () => {
      countDownTime.value = 60;
      timer = setInterval(() => {
        countDownTime.value--;
        if (countDownTime.value <= 0) {
          clearTimer();
        }
      }, 1000);
    };

    const phoneValidVisible = ref<boolean>(false);
    const sendPhoneCode = async () => {
      try {
        const { code } = await request.get(createApiUrl('/newlinkSass/enterprise/captchar/mobile/send'), { params: { mobile: state.userInfo.value.mobile } });
        if (code !== 200) return;
        confirmVisible.value = false;
        phoneValidVisible.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    const makePhoneValid = ():void => {
      if (countDownTime.value > 0) return;
      clearTimer();
      countDown();
      sendPhoneCode();
    };

    const cancel = () => {
      countDownTime.value = 0;
      validCode.value = '';
    };

    const exporting = ref(false);
    const onConfirmExport = async () => {
      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/excel/down/prepaidCard/cardInfo/list'), { ...getParams(), mobile: state.userInfo.value.mobile, verificationCode: validCode.value }, { responseType: 'blob' });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        cancel();
      }

      phoneValidVisible.value = false;
      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhh:mm:ss');
      dispatchDownload(`卡密${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      confirmVisible.value = false;
    };

    return {
      filterParams,
      tableRef,
      tableColumns,
      dataPage,
      dataState,
      cardStatusList,
      bindStatusList,

      loadTableData,
      onRefreshTable,

      loadBatchCardDetailById,

      isStateLoading,

      showConfirmModal,
      confirmInfo,
      onDistribute,
      closeConfirmModal,

      detailInfo,

      onExport,
      confirmVisible,
      exporting,
      onConfirmExport,
      cancel,
      phoneValidVisible,
      makePhoneValid,
      userMobile,
      validCode,
      countDownTime,
      h5Url,
    };
  },
});
