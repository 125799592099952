
import { defineComponent, ref } from 'vue';
import { cardList } from '../dict';

// type detailInfo = {
//   name: string;
//   region: string | undefined;
//   date1: Moment | undefined;
//   delivery: boolean;
//   type: string[];
//   resource: string;
//   desc: string;
//   [propName:string]: any
// }
export default defineComponent({
  props: {
    detailInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const info = ref(props.detailInfo);
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      cardList,
      info,
    };
  },
});
